import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-wahlleitermodal',
  templateUrl: './wahlleitermodal.page.html',
  styleUrls: ['./wahlleitermodal.page.scss'],
})
export class WahlleitermodalPage implements OnInit {
  @Input() scid: string;
  @Input() wahlvorsitz: string;
  @Input() wahlleiterlid: any;
  @Input() disabled: boolean;
  lehrer: any [];
  search: string;
  wahlleiter: any = [];
  hiddenimg = true;

  constructor(public modalController: ModalController, public toastController: ToastController, public apiService: ApiService) { //public navParams: NavParams

  }

  ngOnInit() {
    // const scidtest: string = this.navParams.get('scid');
    // console.log(this.scid);
    // console.log('Wahlleiter pid: ', this.wahlleiterpid);
    // console.log('Wahlleiter length: ', this.wahlleiterpid.length);

    this.getLehrer();
  }

  getLehrer() {
    return new Promise((_resolve) => {
      const scid = {
        scid: this.scid
      };
      // console.log(scid);
      this.apiService.getLehrerApi(scid).subscribe((res: any) => {
        // console.log('SUCCESS ===', res);
        this.lehrer = res;

        for(var i in this.lehrer) {
          if(this.lehrer[i].lid == this.wahlvorsitz) {
            this.lehrer.splice(Number(i), 1)
          }
        }

        if(this.lehrer.length == 0) {
          this.hiddenimg = false;
        }
        else {
          true
        }

        if(this.wahlleiterlid.length !== 0) {
         for(var i in this.wahlleiterlid) {
          this.wahlleiter[this.wahlleiterlid[i]] = true;
         }
        }
      },(error: any) => {
        console.log('ERROR ===', error);
      });
    });
  }

  // checkbox(ev: any) {
  //   // console.log(this.pid);
  //   console.log(ev.target.value);
  //   console.log(ev.target.checked);
  // }

  dismissModal() {
    var wahlleiterlid = [];
    // console.log('Wahllieter: ', this.wahlleiter);

    for(var i in this.wahlleiter) {
      // console.log('Wahlleiter: ', this.wahlleiter[i]);
      if(this.wahlleiter[i] == true) {
        wahlleiterlid.push(i);
      }
    }
    this.modalController.dismiss(wahlleiterlid);
    if(!this.disabled)
      this.presentToast();
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Änderungen erfolgreich gespeichert.',
      duration: 2000,
      color: 'dark',
      // position: 'top'
    });
    toast.present();
  }

}
