import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-profilanzeigenmodal',
  templateUrl: './profilanzeigenmodal.page.html',
  styleUrls: ['./profilanzeigenmodal.page.scss'],
})
export class ProfilanzeigenmodalPage implements OnInit {

  loggedUser = [];
  schulen: any [];
  vorname; nachname; geschlecht; geburtsdatum; kategorie; schule; klasse; email; admin;

  constructor(public modalController: ModalController, public apiService: ApiService, public storageService: StorageService) {
    this.getUserdata();
    // this.getSchuleLehrer();
    // console.log('Userdata', this.userdata);
   }

  ngOnInit() {
  }

  // getUserdata(){
  //   this.apiService.getUserdataApi().subscribe((res: any)=> {
  //     console.log('SUCCESS ===', res);
  //      this.userdata = Array.of(res);
  //   },(error: any) => {
  //     console.log('ERROR ===', error);
  //   });
  // }

  getUserdata() {
    return new Promise(async (_resolve) => {
      this.loggedUser = await this.storageService.getData('user');
      const pid = {
        pid: this.loggedUser[0]
      };

      // console.log(pid);

      this.apiService.getUserdataApi(pid).subscribe((res: any) => {
        // console.log('SUCCESS ===', res);
        // this.userdata = res;
        this.vorname = res.vorname;
        this.nachname = res.nachname;
        this.geschlecht = res.geschlecht;
        this.geburtsdatum = res.geburtsdatum;
        this.kategorie = res.kategorie;
        if(this.kategorie === 'Lehrer') {
          this.getSchuleLehrer();
        }
        else {
          this.schule = res.schule;
          this.klasse = res.klasse;
        }
        this.email = res.schulemail;
        this.admin = res.admin;
        // this.admin = true;
      },(error: any) => {
        console.log('ERROR ===', error);
      });
    });
  }

  getSchuleLehrer() {
    return new Promise(async (_resolve) => {
      this.loggedUser = await this.storageService.getData('user');
      const pid = {
        pid: this.loggedUser[0]
      };

      // console.log(pid);

      this.apiService.getSchuleLehrerApi(pid).subscribe((res: any) => {
        // console.log('SUCCESS ===', res);
        this.schulen = res;
      },(error: any) => {
        console.log('ERROR ===', error);
      });
    });
  }

  dismissModal() {
    this.modalController.dismiss({
      // 'dismissed': true
    });
  }

}
